/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
// usar o estado para alterar os valores quando levado para a tela de Editar

import React, { useRef } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { MdAccountCircle, MdNotificationsActive } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { EditMemberContainer } from './styles'
import { useCompany } from '../../hooks/use-company'
import { useEditMember } from './hook/useEditMember'
import { CorporateInfos } from './CorporateInfos'
import { ApprovesFor } from './ApprovesFor'
import { RequestsFor } from './RequestsFor'
import { SelectInput } from '../../components/SelectInput'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
}

export function EditMember({ isOpen, onRequestClose }: Props) {
    // hooks
    const { member } = useCompany()
    const {
        busy,
        editMemberHandler,
        systemClearance,
        systemClearanceChanger,
        systemClearanceOptions,
        startEditing,
        notifyUser,
    } = useEditMember()

    // state
    const ref = useRef<FormHandles>(null)

    return (
        <ModalContainer
            isOpen={isOpen}
            shouldCloseOnOverlayClick={!startEditing}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-edit-user"
        >
            {!busy ? (
                <>
                    <div className="modal-header">
                        <div className="modal-header-title">
                            <MdAccountCircle />
                            <h1 className="f22-700-dark">
                                {member &&
                                    `${member.firstName} ${member.lastName}`}
                            </h1>
                            <button
                                type="button"
                                className="notifier-btn"
                                onClick={async () => {
                                    const response = await notifyUser(
                                        member?.id || ''
                                    )
                                    if (response) {
                                        toast.success(
                                            `Notificação teste eviada para ${member?.firstName} ${member?.lastName}`
                                        )
                                    }
                                }}
                            >
                                <MdNotificationsActive />
                            </button>
                        </div>
                        <SelectInput
                            name="userType"
                            id="Tipodeusuário"
                            value={{
                                label: systemClearanceOptions.find(
                                    (a) => a.value === systemClearance
                                )?.label,
                                value: systemClearance,
                            }}
                            options={systemClearanceOptions}
                            onChange={(e) => {
                                if (e) {
                                    systemClearanceChanger(e.value)
                                }
                            }}
                        />
                    </div>

                    <EditMemberContainer>
                        <Form ref={ref} onSubmit={() => {}} noValidate>
                            <CorporateInfos />
                            <ApprovesFor />
                            <RequestsFor />
                        </Form>
                    </EditMemberContainer>
                    {startEditing ? (
                        <div className="action-btns">
                            <div className="cancel">
                                <Button
                                    color="PRIMARY"
                                    buttonType="BORDERED"
                                    text="Cancelar"
                                    type="button"
                                    onClick={() => {
                                        onRequestClose()
                                    }}
                                />
                            </div>
                            <div className="save">
                                <Button
                                    color="PRIMARY"
                                    buttonType="FILLED"
                                    text="Salvar"
                                    busy={busy}
                                    type="submit"
                                    onClick={async () => {
                                        const response =
                                            await editMemberHandler()
                                        if (response) onRequestClose()
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="action-btns">
                            <div className="cancel">
                                <Button
                                    color="PRIMARY"
                                    buttonType="BORDERED"
                                    text="Fechar"
                                    type="button"
                                    onClick={() => {
                                        onRequestClose()
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div />
            )}
        </ModalContainer>
    )
}
