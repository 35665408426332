import styled from 'styled-components'

export const Table = styled.div`
    width: 100%;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 2rem;
    overflow: hidden;
    .table-content {
        min-height: 25rem;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        > *:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    .table-header {
        background: #f6f7f8;
        width: 100%;
        display: grid;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        h1 {
            font-size: 1.6rem;
            font-weight: 700;
            color: #646669;
            padding: 1.9rem 0;
            &:not(:last-child) {
                border-right: 2px solid #c8cacd;
            }
        }
        .center {
            text-align: center;
        }
        .start {
            text-align: start;
            padding-left: 1.9rem;
        }
        .end {
            text-align: end;
        }
    }
`
