import styled from 'styled-components'

export const CompanyInfoContainer = styled.div`
    padding-bottom: 4rem;

    form {
        .label-input {
            padding: 2.7rem 2rem;
            border-bottom: 2px solid #f6f7f8;
            .label {
                margin-bottom: 1.2rem;
                font-weight: 700;
                font-size: 1.6rem;
                color: #000;
                span {
                    font-style: italic;
                    font-weight: 500;
                    font-size: 1.4rem;
                    color: #646669;
                }
            }
        }
    }
`
