/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import { AiFillDollarCircle, AiFillEye } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { GiShieldDisabled } from 'react-icons/gi'
import { ExpenseCardContainer } from './styles'
import {
    expenseTypeIcon,
    expensesIconHandler,
} from '../../utils/expensnes/ExpensesIconsHandler'
import { expensesDateFormatter } from '../../utils/expensnes/dateFormatter'
import TravelerTag from '../TravelerTag'
import { priceFormatter } from '../../utils/expensnes/priceFormatter'
import { Button } from '../Button'
import { useExpenses } from '../../hooks/use-expenses'
import { Expenses } from '../../interfaces/expenses'
import { ExpensePaymentMethod } from '../../enums/expense-payment-method.enum'
import ConfirmPaymentModal from '../../modals/ConfirmPaymentModal'
import { expenseOutOfPolicyHandler } from '../../utils/expensnes/expenseOutOfPolicyHandler'
import { expenseStatusLabel } from '../../utils/expensnes/expenseStatusLabel'
import { ExpenseStatus } from '../../enums/expenses-status.enum'

interface Props {
    expense: Expenses
    hasBatchLogic: boolean
    tabValue: ExpenseStatus
}

export default function ExpenseCard({
    expense,
    hasBatchLogic,
    tabValue,
}: Props) {
    const [isLoading, setLoading] = useState<boolean>(false)
    const { openModalHandler, completeExpenses } = useExpenses()
    const [openConfirmationModal, setConfirmationModal] = useState(false)

    async function conciliateExpensesHandler(date?: Date) {
        setLoading(true)
        const response = date
            ? await completeExpenses(expense.id, date)
            : await completeExpenses(expense.id, undefined)
        if (response) {
            toast.success('Conciliação realizada com sucesso!')
        }
        setLoading(false)
    }

    function amountLabel() {
        if (
            expense.info.currency?.code &&
            expense.info.currency?.code !== 'BRL'
        ) {
            return `${expense.info.currency.code} ${priceFormatter(
                expense.info.amount || 0
            )}`
        }
        if (expense.info.amountInBRL) {
            return `R$${priceFormatter(expense.info.amountInBRL || 0)}`
        }

        return `R$${priceFormatter(expense.info.amount || 0)}`
    }

    if (expense.type === 'MILEAGE') {
        return (
            <ExpenseCardContainer>
                <div className="description">
                    <div className="image">
                        <img
                            src={expenseTypeIcon('MILEAGE')}
                            alt="expense-image"
                        />
                    </div>
                    <div className="observations">
                        <h3 className="f16-500-dark">
                            {`Despesa ${
                                expense.numberIdentifier
                                    ? `${expense.numberIdentifier}`
                                    : ''
                            }`}
                        </h3>
                        <p className="f14-500-gray">Quilometragem</p>
                        <p className="f14-500-gray">
                            {expensesDateFormatter(
                                new Date(
                                    expense.info.departureDateTime
                                ).toDateString()
                            )}
                        </p>
                    </div>
                </div>
                <div className="traveler">
                    <TravelerTag
                        photo={
                            expense.travelers.find(
                                (traveler) => traveler.id === expense.createdBy
                            )?.photoUrl || undefined
                        }
                        firstName={
                            expense.travelers.find(
                                (traveler) => traveler.id === expense.createdBy
                            )?.firstName || ''
                        }
                        lastName={
                            expense.travelers.find(
                                (traveler) => traveler.id === expense.createdBy
                            )?.lastName || ''
                        }
                    />
                    {expense.travelers.length > 1 && (
                        <p>+{expense.travelers.length - 1}</p>
                    )}
                </div>
                <div className="out-of-policy">
                    {expenseOutOfPolicyHandler(
                        expense.travelerClearanceEvaluation
                    ).isOutOfPolicy ? (
                        <GiShieldDisabled />
                    ) : (
                        <div />
                    )}
                </div>
                <div className="price start">
                    <p className="f18-700-dark">{`R$${priceFormatter(
                        expense.info.amountDue || 0
                    )}`}</p>
                    <p className="f14-500-gray">
                        {expense.payment.method === ExpensePaymentMethod.CASH
                            ? 'Dinheiro próprio'
                            : expense.payment.method ===
                              ExpensePaymentMethod.NON_REFUNDABLE
                            ? 'Cartão de crédito'
                            : 'Adiantamento'}
                    </p>
                </div>
                <div className="action-btns">
                    {expense.status === 'CONFIRMED' ? (
                        <Button
                            type="button"
                            buttonType={
                                expense.status === 'CONFIRMED' && !hasBatchLogic
                                    ? 'FILLED'
                                    : 'BORDERED'
                            }
                            icon={AiFillDollarCircle}
                            color={
                                expense.status === 'CONFIRMED' && !hasBatchLogic
                                    ? 'PRIMARY'
                                    : 'TERTIARY'
                            }
                            text={
                                expense.status === 'CONFIRMED'
                                    ? `${
                                          tabValue ===
                                          ExpenseStatus.CONFIRMED_CREDIT_CARD
                                              ? 'Confirmar conciliação'
                                              : 'Confirmar reembolso'
                                      }`
                                    : 'Pagamento conciliado'
                            }
                            onClick={() => {
                                if (
                                    expense.payment.method ===
                                    ExpensePaymentMethod.CASH
                                ) {
                                    return setConfirmationModal(true)
                                }
                                return conciliateExpensesHandler()
                            }}
                        />
                    ) : (
                        <div className="expenses-label">
                            <p className="f16-500-gray">
                                {expenseStatusLabel(expense.status)}
                            </p>
                        </div>
                    )}
                    <button
                        onClick={() => openModalHandler(expense)}
                        className="see-more-btn"
                        type="button"
                    >
                        <AiFillEye />
                    </button>
                </div>
                {openConfirmationModal && (
                    <ConfirmPaymentModal
                        isOpen={openConfirmationModal}
                        callback={(date?: Date) =>
                            conciliateExpensesHandler(date)
                        }
                        onRequestClose={() => setConfirmationModal(false)}
                    />
                )}
            </ExpenseCardContainer>
        )
    }

    return (
        <ExpenseCardContainer>
            <div className="description">
                <div className="image">
                    <img
                        src={expensesIconHandler(expense.info.type)}
                        alt="expense-image"
                    />
                </div>
                <div className="observations">
                    <h3 className="f16-500-dark">
                        {`Despesa ${
                            expense.numberIdentifier
                                ? `${expense.numberIdentifier}`
                                : ''
                        }`}
                    </h3>
                    <p className="f14-500-gray">
                        {expense.info.expenseCategory.label}
                    </p>
                    <p className="f14-500-gray">
                        {expensesDateFormatter(
                            new Date(expense.datetime).toDateString()
                        )}
                    </p>
                </div>
            </div>
            <div className="traveler">
                <TravelerTag
                    photo={
                        expense.travelers.find(
                            (traveler) => traveler.id === expense.createdBy
                        )?.photoUrl || undefined
                    }
                    firstName={
                        expense.travelers.find(
                            (traveler) => traveler.id === expense.createdBy
                        )?.firstName || ''
                    }
                    lastName={
                        expense.travelers.find(
                            (traveler) => traveler.id === expense.createdBy
                        )?.lastName || ''
                    }
                />
                {expense.travelers.length > 1 && (
                    <p>+{expense.travelers.length - 1}</p>
                )}
            </div>
            <div className="out-of-policy">
                {expenseOutOfPolicyHandler(expense.travelerClearanceEvaluation)
                    .isOutOfPolicy ? (
                    <GiShieldDisabled />
                ) : (
                    <div />
                )}
            </div>
            <div className="price start">
                <p className="f18-700-dark">{amountLabel()}</p>
                <p className="f14-500-gray">
                    {expense.payment.method === ExpensePaymentMethod.CASH
                        ? 'Dinheiro próprio'
                        : expense.payment.method ===
                          ExpensePaymentMethod.NON_REFUNDABLE
                        ? 'Cartão de crédito'
                        : 'Adiantamento'}
                </p>
                <p className="f14-500-gray">
                    {expense.payment.creditCard
                        ? `${expense.payment.creditCard.label}`
                        : ''}
                </p>
            </div>
            <div className="action-btns">
                {expense.status === 'CONFIRMED' ? (
                    <Button
                        type="button"
                        buttonType={
                            expense.status === 'CONFIRMED' && !hasBatchLogic
                                ? 'FILLED'
                                : 'BORDERED'
                        }
                        icon={AiFillDollarCircle}
                        color={
                            expense.status === 'CONFIRMED' && !hasBatchLogic
                                ? 'PRIMARY'
                                : 'TERTIARY'
                        }
                        text={
                            expense.status === 'CONFIRMED'
                                ? `${
                                      tabValue ===
                                      ExpenseStatus.CONFIRMED_CREDIT_CARD
                                          ? 'Confirmar conciliação'
                                          : 'Confirmar reembolso'
                                  }`
                                : 'Pagamento conciliado'
                        }
                        onClick={() => {
                            if (
                                expense.payment.method ===
                                ExpensePaymentMethod.CASH
                            ) {
                                return setConfirmationModal(true)
                            }
                            return conciliateExpensesHandler()
                        }}
                    />
                ) : (
                    <div className="expenses-label">
                        <p className="f16-500-gray">
                            {expenseStatusLabel(expense.status)}
                        </p>
                    </div>
                )}
                <button
                    onClick={() => openModalHandler(expense)}
                    className="see-more-btn"
                    type="button"
                >
                    <AiFillEye />
                </button>
            </div>
            {openConfirmationModal && (
                <ConfirmPaymentModal
                    isOpen={openConfirmationModal}
                    callback={(date?: Date) => conciliateExpensesHandler(date)}
                    onRequestClose={() => setConfirmationModal(false)}
                />
            )}
        </ExpenseCardContainer>
    )
}
