import styled from 'styled-components'

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    .disabled {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
    }
    .second-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.5rem;
        width: 100%;
        margin-bottom: 2rem;
        .align-right {
            .select-type {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.7rem;
                margin-left: 1rem;
            }
        }
        .exibition {
            color: #646669;
            font-weight: 500;
            font-size: 1.4rem;
            font-style: italic;
        }
    }
    .third-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        width: 100%;
        margin-bottom: 2rem;
        .align-right {
            margin-left: auto;
            display: flex;
            align-items: center;
            .select-type {
                display: flex;
                align-items: center;
                justify-content: stretch;
                gap: 0.7rem;
                margin-left: 1rem;
            }
        }
        .exibition {
            color: #646669;
            font-weight: 500;
            font-size: 1.4rem;
            font-style: italic;
        }
    }
    .thid-row {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .first-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }
    .margin-bottom {
        margin-bottom: 2rem;
    }
`
export const TextFilter = styled.div`
    display: inline-block;
    margin-right: 0.5rem;

    label {
        width: 15rem;
        height: 4.3rem;
        border: 1px solid #c7d3d1;
        border-radius: 1rem;
        background-color: #fff;
        display: flex;
        overflow: hidden;
        align-items: center;

        svg {
            margin: 0 1rem;
            height: 1.8rem;
            width: 1.8rem;
        }
        input {
            height: 100%;
            border: none;
            outline: none;
            width: 100%;
        }
    }
`

export const Selector = styled.div`
    .select-label {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 1rem 1rem;
        p {
            margin-left: 2rem;
        }
        input {
            position: absolute;
            margin-right: 2rem;
            height: 1.5rem !important;
            width: 1.5rem !important;
        }
        &:hover {
            background-color: rgba(231, 75, 112, 0.05);
            cursor: pointer;
        }
    }
`

export const PeriodDropDown = styled.div`
    position: relative;
    display: inline-block;
    min-width: 10rem;
    .selector-label {
        height: 5rem;
        border: 1px solid #c8cacd;
        border-radius: 0.8rem;
        background-color: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 1rem;

        h4 {
            display: block;
            width: 80%;
            max-width: 15.2rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        svg {
            margin: 0 0.5rem;
            height: 1rem;
            width: 1rem;
            transition: transform 0.5s ease;
        }
        .selectors {
            position: absolute;
            top: 5rem;
            left: 0;
            width: 35rem;
            border: 1px solid #c7d3d1;
            border-radius: 1rem;
            background-color: #fff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 1.5rem;
            display: none;
            z-index: 50;
            max-height: 50rem;
            overflow-y: scroll;
            /* .filter-input {
                margin-top: 1rem;
            } */
            .title-dropdown {
                display: flex;
                align-items: center;
                justify-content: space-between;
                h1 {
                    font-size: 1.6rem;
                    font-weight: 500;
                }
                padding-bottom: 1rem;
                border-bottom: 1px solid #c2c2c2;
                .btns {
                    display: flex;
                    align-items: center;
                    button {
                        width: 10rem;
                        border: none;
                        background-color: inherit;
                        font-size: 1.2rem;
                        outline: none;
                    }
                    .select-all {
                        margin-right: 1rem;
                        color: var(--secondary);
                    }
                    .clear-all {
                        color: #333;
                    }
                }
            }
        }
        .selector-input[type='checkbox'] {
            display: none;
        }
        .selector-input[type='checkbox']:checked ~ svg {
            transform: rotate(180deg);
        }
        .selector-input[type='checkbox']:checked ~ .selectors {
            display: block;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .bordered {
        border: 2px solid #000;
    }
`

export const SelectButtons = styled.div`
    display: flex;
    flex-direction: column;
    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        justify-content: space-between;
        border: none;
        background-color: inherit;
        outline: none;
        svg {
            height: 2rem !important;
            width: 2rem !important;
            color: var(--tertiary);
            display: none;
        }
        &:hover {
            background-color: rgba(231, 75, 112, 0.05);
            cursor: pointer;
        }
    }
    .selected {
        font-weight: 700;
        svg {
            display: block;
        }
    }
`
