/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { Button } from '../../../components/Button'
import { EditProfileDto } from '../../../dtos/edit-profile'
import { User } from '../../../entities/user'
import { Input } from '../../../UnformInputs/Input'
import { getValidationErrors } from '../../../utils/getValidationErrors'
import { phoneFormatter } from '../../../utils/maskFunctions'
import { ProfileContainer } from './styles'

interface Props {
    member: User
}

export function UserProfile({ member }: Props) {
    // ref
    const formRef = useRef<FormHandles>(null)
    const formPasswordRef = useRef<FormHandles>(null)

    // state
    const [busyBtn, setBusyBtn] = useState(false)

    useEffect(() => {
        formRef.current?.setData({
            firstName: member.firstName,
            lastName: member.lastName,
            userCorporateEmail: member.companyInfo[0].userCorporateEmail,
            email: member.email,
            userCorporatePhoneNumber: '',
            phoneNumber: phoneFormatter(member.phoneNumber, 'FROM_API'),
        })
    }, [])

    async function handleEditProfile(data: {
        firstName: string
        lastName: string
        phoneNumber: string
    }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                firstName: Yup.string().required('Nome inválido'),
                lastName: Yup.string().required('Sobrenome inválido'),
                phoneNumber: Yup.string().required('Telefone inválido'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            const editProfileData: EditProfileDto = {
                phoneNumber:
                    phoneFormatter(data.phoneNumber, 'TO_API') || undefined,
                firstName: data.firstName || undefined,
                lastName: data.lastName || undefined,
            }
            // await editUserProfile(editProfileData)
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    async function handleEditPassword(data: {
        currentPassword: string
        newPassword: string
    }) {
        setBusyBtn(true)
        try {
            formPasswordRef.current?.setErrors({})
            const schema = Yup.object().shape({
                newPassword: Yup.string()
                    .required('Insira uma senha valida ')
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                        'Senha deve conter: letra maiúscula; letra minúscula; número, caracter especial. Mínimo de 6 dígitos.'
                    )
                    .max(32, 'A senha pode conter no máximo 32 dígitos'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            const editPasswordData: EditProfileDto = {
                password: {
                    current: data.currentPassword,
                    new: data.newPassword,
                },
            }
            // await editUserProfile(editPasswordData)
            setBusyBtn(false)
        } catch (error) {
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            setBusyBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formPasswordRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <ProfileContainer>
            <div className="profile-info">
                <Form ref={formRef} onSubmit={handleEditProfile} noValidate>
                    <div className="profile-info-header">
                        <div className="profile-info-header--title">
                            <h1 className="f18-700-dark">Perfil interno</h1>
                            <p className="f16-500-gray">
                                Informações que serão exibidas para nossos
                                agentes e para outros membros da sua empresa
                            </p>
                        </div>
                        <Button
                            buttonType="TEXT"
                            color="PRIMARY"
                            text="Salvar"
                            busy={busyBtn}
                        />
                    </div>
                    <div className="profile-info-form">
                        <Input
                            label="Primeiro nome"
                            normalLabel
                            name="firstName"
                        />
                        <Input label="Sobrenome" normalLabel name="lastName" />
                        <Input
                            label="E-mail corporativo"
                            normalLabel
                            name="userCorporateEmail"
                            disabled
                        />
                        <Input
                            label="E-mail pessoal"
                            normalLabel
                            name="email"
                        />
                        <Input
                            label="Telefone corporativo"
                            normalLabel
                            name="userCorporatePhoneNumber"
                            disabled
                        />
                        <Input
                            label="Telefone pessoal"
                            normalLabel
                            name="phoneNumber"
                        />
                    </div>
                </Form>
            </div>
            <div className="profile-info">
                <Form
                    ref={formPasswordRef}
                    onSubmit={handleEditPassword}
                    noValidate
                >
                    <div className="profile-info-header">
                        <div className="profile-info-header--title">
                            <h1 className="f18-700-dark">Alterar senha</h1>
                            <p className="f16-500-gray">
                                Confirme sua senha atual e digite sua nova senha
                            </p>
                        </div>
                        <Button
                            buttonType="TEXT"
                            color="PRIMARY"
                            text="Salvar"
                        />
                    </div>
                    <div className="profile-info-form">
                        <Input
                            label="Senha atual"
                            normalLabel
                            name="currentPassword"
                            isPassword
                        />
                        <Input
                            label="Nova senha"
                            normalLabel
                            name="newPassword"
                            isPassword
                        />
                    </div>
                </Form>
            </div>
        </ProfileContainer>
    )
}
